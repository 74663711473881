export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'regionCode',
    label: 'field.regionCode',
    hideToggle: true,
  },
  {
    key: 'telegramId',
    label: 'field.telegram',
  },
  {
    key: 'totalAgent',
    label: 'field.totalAgent',
    hideToggle: true,
  },
  {
    key: 'updatedAt',
    sortable: true,
    sortField: 'updatedAt',
    label: 'field.updatedDate',
    hideToggle: true,
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
  },
  {
    key: 'actions',
    label: 'field.action',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
];
